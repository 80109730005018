<template>
  <div class="page-main">
    <SaleTabs />
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom :loading="loading">
      <template #buttons>
        <el-button
          v-if="$tool.hasControl(['/gcvip/checkBill/leaderDoAudit'])"
          type="success"
          size="mini"
          @click="itemPass"
        >
          审核通过
        </el-button>
        <el-button
          v-if="$tool.hasControl(['/gcvip/checkBill/leaderReject'])"
          type="danger"
          size="mini"
          @click="itemReject"
        >
          审核拒绝
        </el-button>
        <el-button type="default" size="mini" @click="itemPrint">打印</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      :merge-cells="mergeCell"
      @cell-dblclick="itemPrint"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_out_stock_date="{ row }">
        {{ row.out_stock_date.slice(0, 10) }}
      </template>
      <template #default_out_stock_bill_code="{ row }">
        <el-link type="primary" @click="itemDeliveryEdit(row.out_stock_bill_code)">
          {{ row.out_stock_bill_code }}
        </el-link>
      </template>
      <template #default_bill_code="{ row }">
        <el-link type="primary" @click="itemEdit"> {{ row.bill_code }} </el-link>
      </template>
      <template #default_pinming="{ row }">
        {{ row.craft }}
        {{ row.texture }}
      </template>
      <template #default_status_name="{ row }">
        <span :style="{ color: statusStyle(row) }"> {{ row.status_name }} </span>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 编辑送货单 -->
    <PopupShEdit
      v-if="isShowPopupShEdit"
      :show.sync="isShowPopupShEdit"
      :code="activeDeliveryCode"
      @success="searchListData"
    />
  </div>
</template>

<script>
import SaleTabs from '../../common/sale-tabs'
import PopupShEdit from '../../../common/popup-sh-edit'
export default {
  components: {
    SaleTabs,
    PopupShEdit
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        // out_stock_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },
      sortData: {
        out_stock_date: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/checkBill/searchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          isMerge: true,
          width: 40
        },
        {
          title: 'ID',
          field: 'out_stock_bill_id',
          isMerge: true,
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货日期',
          field: 'out_stock_date',
          isMerge: true,
          width: 180,
          slots: { header: 'header_daterange', default: 'default_out_stock_date' }
        },
        {
          title: '送货单号',
          field: 'out_stock_bill_code',
          isMerge: true,
          width: 120,
          slots: { header: 'header_autocomplete', default: 'default_out_stock_bill_code' }
        },
        {
          title: '客户名称',
          field: 'company_name',
          isMerge: true,
          width: 240,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '销售单号',
          field: 'bill_code',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_bill_code' }
        },
        {
          title: '送货状态',
          field: 'status_name',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_status_name' }
        },
        {
          title: '品名',
          field: '',
          width: 120,
          slots: { header: 'header_autocomplete', default: 'default_pinming' }
        },
        {
          title: '产品规格',
          field: 'sales_specification',
          width: 120,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货数量',
          field: 'counts',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单位',
          field: 'unit',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货金额(元)',
          field: 'sales_amount',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '司机电话',
          field: 'driver_phone',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货地址',
          field: 'address',
          isMerge: true,
          width: 240,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货备注',
          field: 'out_bill_remark',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page,
        pageSize: 500
      },
      totalObj: {
        counts_all: '',
        sales_amount_all: ''
      },

      activeBillId: null,
      activeDeliveryCode: null,
      isShowPopupShEdit: false
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    statusStyle(row) {
      let result = ''
      if (row.status_name === '待审核') result = '#66b1ff'
      if (row.status_name === '已审核') result = '#67c23a'
      return result
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/outStock/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.counts_all = res.data.counts_all
            this.totalObj.sales_amount_all = res.data.sales_amount_all
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'out_stock_bill_id'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'counts') return this.totalObj.counts_all
          if (column.field === 'sales_amount') return this.totalObj.sales_amount_all
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-编辑
    itemEdit() {
      setTimeout(() => {
        let row = this.getRadioRecord()
        if (row) {
          this.itemEditAction(row.bill_id)
        } else {
          this.itemChoosePrompt()
        }
      }, 100)
    },
    // 项-编辑-执行
    itemEditAction(id) {
      this.$router.push(`/sale/order/old-detail?id=${id}`)
    },
    // 项-审核拒绝
    itemReject() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemRejectAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审核拒绝
    itemRejectAction(row) {
      this.$confirm(`送货单号：${row.out_stock_bill_code}，该送货单是否驳回审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.itemRejectConfirm(row.out_stock_bill_id)
        })
        .catch(() => {})
    },
    // 项-审核拒绝-确认
    itemRejectConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/outStock/changeStatus',
        params: {
          id,
          status_name: '待审核'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已拒绝')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审核通过
    itemPass() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPassAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审核通过
    itemPassAction(row) {
      this.$confirm(`送货单号：${row.out_stock_bill_code}，该送货单是否通过审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.itemPassConfirm(row.out_stock_bill_id)
        })
        .catch(() => {})
    },
    // 项-审核通过-确认
    itemPassConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/outStock/changeStatus',
        params: {
          id,
          status_name: '已审核'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审核已通过')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-打印
    itemPrint() {
      setTimeout(() => {
        let row = this.getRadioRecord()
        if (row) {
          this.itemPrintAction(row.out_stock_bill_code)
        } else {
          this.itemChoosePrompt()
        }
      }, 100)
    },
    // 项-打印-执行
    itemPrintAction(code) {
      window.open(`/sale/delivery/print?code=${code}`, '_blank')
    },
    // 项-编辑送货单
    itemDeliveryEdit(code) {
      this.activeDeliveryCode = code
      this.isShowPopupShEdit = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
